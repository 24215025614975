<template>
  <div></div>
</template>

<script>
  import moment from 'moment'
  import { mapActions } from 'vuex'
  import Client from '@/apis/liff/GroupBuying'

  export default {
    data: () => ({
    }),
    async created() {
      try {
        await this.logThemeGroupbuyingVisit();
      } catch (error) {
        console.log(error);
      }
      await this.redirectThemeGroupbuyUrl();
    },

    computed: {
      visitData() {
        return {
          group_id: this.$route.query.group_id,
          branch_id: this.$route.query.branch_id,
          redirectUrl: this.$route.query.redirect_url,
          theme_gb_id: this.$route.query.theme_gb_id,
        }
      }
    },

    methods: {
      ...mapActions({
        getBranchCodeApi: 'liffGroupBuying/getBranchCode'
      }),

      async logThemeGroupbuyingVisit() {
        const { branch_id, group_id, theme_gb_id } = this.visitData;
        await Client.getInstance().logThemeGroupbuyingVisit({ branch_id, group_id, theme_gb_id })
      },

      async redirectThemeGroupbuyUrl() {
        const { redirectUrl, branch_id } = this.visitData
        const branch_code = await this.getBranchCodeApi({ id : branch_id })
        const timeStamp = moment().valueOf();
        window.location.href = `${redirectUrl}?utm_source=groupbuy&utm_medium=product_referral_line&utm_campaign=common&tripid=:${branch_code}::${timeStamp}`
      },
    },
  }
</script>
